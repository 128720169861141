import Header from "../headers/light.js";


function Cancel()  {
  console.log('Cancel');

    return (
      <>
      <Header/>
      <br/>
      <br/>
      <div>
        <h1>Cancel</h1>
        <h2>Your payment was canceled.</h2>
      </div>
      </>
    );
  };
  
  export default Cancel;
  