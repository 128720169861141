import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
//<script src="https://maps.googleapis.com/maps/api/js?key=<AIzaSyBoI-8CUJi8Z1zeQf9gBxGvW_JLQzydNEk>&libraries=places&callback=initMap"async></script>

Modal.setAppElement("#root");
//const cors = require('cors');
//const corsOptions ={
//    origin:'http://localhost:3000', 
//    credentials:true,            //access-control-allow-credentials:true
//    optionSuccessStatus:200
//}
//Ap.use(cors(corsOptions));
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);